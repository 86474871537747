/**
 * Additional admin styling for .demo-banner blocks
 */
 .demo-banner {
    width: 200px;
    background: #a94442;
    text-shadow: 0 1px darken(#a94442, 20%);
    font-weight: 700;
    position: fixed;
    z-index: 9999;
    bottom: 25px;
    left: -50px;
    text-align: center;
    line-height: 50px;
    color: #f0f0f0;
    transform: rotate(45deg);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
    text-transform: uppercase;
    cursor: help;
}
