/**
 * Additional admin styling for .table blocks
 */
 .table {
    // Generate .pct-10, pct-20 etc to allow force setting of widths in 10% blocks
    .pct-loop (@i) when (@i <= 10) {
        .pct-@{i}0 {
            width: @i * 10%;
        }
        .pct-loop(@i + 1);
    }
    .pct-loop(1);

    &--col-seven {
        td {
            width: (100% / 7);
        }
    }

    &--col-fourteen {
        td {
            width: (100% / 14);
        }
    }

    &--fa-col {
        width: 20px;
    }

    &--actions-fw {
        width: 100px;
    }

    &--smallest {
        width: 1%;
        white-space: nowrap;
    }

    &--groupings {
        & tr,
        & td {
            transition: all 0.2s ease-in-out;
        }

        & td:not(.table--allow-wrap) {
            white-space: nowrap;
        }
    }

    &--resubmission {
        color: #a0a0a0;
        font-style: italic;

        > td {
            border-top-style: dotted;
        }

        .fa.text-success,
        .fa.text-danger {
            opacity: 0.4;
        }
    }

    &--resubmission + &--resubmission {
        > td {
            border-top-style: none;
        }
    }

    &--group-start {
        box-shadow: inset 0px 3px 3px -2px rgba(60, 141, 188, 1);
    }

    &--group-end {
        box-shadow: inset 0px -2px 3px -2px rgba(60, 141, 188, 1);
    }

    &--group-highlight > td {
        background: rgba(60, 141, 188, 0.05);
    }

    &--groupings tbody tr:not([class]):hover {
        background: rgba(60, 141, 188, 0.05);
        box-shadow: inset 0px -2px 3px -2px rgba(60, 141, 188, 1),
            inset 0px 3px 3px -2px rgba(60, 141, 188, 1);
    }
}
