/**
 * Additional admin styling for .sidebar-menu blocks
 */
.sidebar-menu {
    li > a {
        .pull-right {
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -7px;
        }

        .fa-angle-left {
            width: auto;
            height: auto;
            padding: 0;
            margin-right: 10px;
        }
    }

    li.active > a > .fa-angle-left {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .treeview-menu > li > a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
