/**
 * Additional admin styling for .direct-chat blocks
 */
.direct-chat-messages {
    max-height: 250px;
    height: auto;
}

.direct-chat-text {
    overflow: hidden;

    &--no-image {
        margin: 5px 0 0 !important;
    }

    &-footer {
        padding-top: 5px;
        margin-top: 5px;
        border-top: 1px solid #bbb;
    }

    &-outcome {
        margin-left: 18px;
    }
}
