/**
 * Additional admin styling for elements in lists
 */
.list-unstyled {
    .divider {
        height: 1px;
        overflow: hidden;
        margin: 9px 0;
        background-color: #eee;
    }
}

.list--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Make dropdown menus stand out a bit more
.dropdown-menu {
    border: 1px solid #d2d6de;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    .disabled a,
    .disabled a:hover {
        color: #bbb;
        text-shadow: 0 1px #fff;
    }
}
