/**
 * Remove number spinner on type="number" inputs.
 */
.form-control--no-spin {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield !important;
}

/**
 * Applied to day/month/year containers of multi-field date input.
 */
.form-date-component {
    display: inline-block;

    .form-control {
        // @todo Actually 2 * @padding-base-horizontal but admin uses precompiled
        //       stylesheet.
        width: 4em;
        width: calc(~'2em + 24px');
    }

    &--year {
        .form-control {
            // @todo Actually 2 * @padding-base-horizontal but admin uses
            //       precompiled stylesheet.
            width: 6em;
            width: calc(~'4em + 24px');
        }
    }

    .control-label {
        font-weight: normal;
    }
}

.has-error .help-block {
    a {
        color: inherit;
        font-weight: 600;
    }
}

.form-control-feedback {
    right: 0 !important;
}

// Adds some styling for having form elements like table cells.
.form-control {
    &--cell {
        border-color: #f4f4f4;
        border-top-color: #ffffff;
        border-bottom-color: #ffffff;
    }
}

.table .table--form-control {
    padding: 0;
}
