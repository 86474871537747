/**
 * Additional admin styling for .small-box blocks
 */
.small-box {
    // Stop text from overflowing .small-box bounds
    h3 {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    // Disable the animation of the icon in a .small-box
    &--no-icon-anim:hover .icon {
        font-size: 90px;
    }
}
