/**
 * Additional admin styling for form elements
 */
.form-group-heading {
    font-size: 21px;
    font-weight: 300;
    margin: 0 0 20px;
    line-height: 1.4;
}

.form-group__no-label {
    padding-top: 22px;
}

.resizable--h {
    resize: vertical;
    overflow: auto; // Safari

    .form-control& {
        min-height: 34px;
    }
}

// Re-enable pointer events on feedback icons if a tooltip.
.form-control-feedback.js-tooltip {
    pointer-events: inherit;
}

.purchase-radio-row {
    .control-label {
        display: block;
    }
}
