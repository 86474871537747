.login-banner {
    position: fixed;
    top: 120px;
    left: 0;
    width: 231px;
    padding: 0 20px 20px;
    background-color: #FFF;
    border: 1px solid #024f76;
    border-radius: 0 4px 4px 0;
    border-left: 0;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    line-height: 20px;

    &__title {
        display: block;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #024f76;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
    }

    &__close {
        position: absolute;
        top: 3px;
        left: 213px;
        font-size: 14px;
        cursor: pointer;
    }

    a {
        color: #024f76;
        font-size: 14px;
        text-decoration: none;
    }

    button {
        color: #024f76;
        padding: 0 !important;
        background: none !important;
        border: none;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
    }
}
