/**
 * Additional admin utitlity classes that can be used throughout
 */
.separator {
    &--top {
        border-top: 1px solid #f4f4f4;
        padding-top: 10px;
        margin-top: 10px;
    }
}

.no-margin {
    &--bottom {
        margin-bottom: 0;
    }
}

.js-note {
    &__saved {
        transition: all 0.2s ease;
    }
}

.receipt-icon {
    color: #367fa9;
    font-size: 30px;
    padding: 10px;
}

.text {
    // Allows a text block to be shown inline with buttons
    &__btn-inline {
        display: inline-block;
        padding: 7px 12px;
        border-radius: 3px;
        margin-right: 5px;
    }

    &-white {
        color: #fff;
    }
}

.box-reports {
    max-height: 250px;
    height: auto;
    overflow-y: scroll;
}

.btn {
    // Useful when there's a short word on a button, to increase clickable area
    &--wide {
        padding-left: 40px;
        padding-right: 40px;
    }

    &--text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.btn.text-danger {
    &:hover {
        color: rgba(169, 68, 66, 0.80)!important;
    }
}

.btn.text-warning {
    &:hover {
        color: rgba(138, 109, 59, 0.8)!important;
    }
}

.btn-note-attachments {
    background-color: #FFFFFF;
    &:hover {
        box-shadow: none!important;
        background-color: #FFFFFF;
    }
}

.label {
    &--btn {
        display: inline-block;
        padding: 7px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
    }
}

.dd-descriminator {
    font-style: normal;
    font-weight: 600;
}

.box {
    &--breadcrumbs {
        border-radius: 0;
        border-top: none;
        margin-bottom: 0;

        .breadcrumb {
            margin-bottom: 0;
        }
    }
}

// Universal override to hide underlines on abbr tags
abbr[title] {
    text-decoration: none;
}

// Universal override to hide overflow on wrappers
.wrapper {
    overflow: hidden;
}

// Override the dropdown menu link colouring
.skin-blue .main-header .navbar .dropdown-menu li a {
    color: #777;

    &:hover {
        background-color: #e1e3e9;
        color: #333;
    }
}

.navbar-nav > .user-menu > .dropdown-menu > .user-body a:hover {
    background-color: #e1e3e9 !important;
}

.nav-pills {
    > li > a > .nav-pill__badge {
        position: absolute;
        bottom: -8px;
        left: 0;

        margin-left: 0;
        text-align: center;
        width: 100%;
    }
}

// Shrink claim review task list table on dashboard
@media screen and (min-width: 1200px) and (max-width: 1720px) {
    .claim-review-task-list {
        .visible-lg,
        .visible-lg-inline {
            display: none !important;
        }
    }
}

ul.dropdown-menu {
    // Hide a `divider` element if it's the first in a dropdown menu
    > .divider:first-child {
        display: none;
    }

    // Have the link colour be more in contrast to disabled elements
    > li > a {
        color: #333;
    }
}

.preview-frame {
    height: 500px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
}

.preview-image {
    margin-bottom: 20px;
}
