/**
 * Additional admin styling for .box-body__toolbox blocks
 */
.box-body {
    &__toolbox-icon {
        font-size: 23px;
        padding-right: 6px;
        margin-right: 6px;
        margin-left: 30px;
        display: inline-block;
        color: #d2d6de;
        border: 1px solid transparent;
        border-right: 1px solid #d2d6de;

        &:first-child {
            margin-left: 0;
        }
    }

    &__toolbox-actions {
        &:empty:before {
            content: "No actions currently available";
            color: #d2d6de;
            padding: 6px 12px;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            display: inline-block;
        }

        .wider-input {
            width: 300px !important;
        }
    }
}

.box-tools__btn,
.box-tools__form {
    float: left;
    margin-left: 3px;
}

.box-tools__btn:first-child,
.box-tools__form:first-child {
    margin-left: 0;
}
