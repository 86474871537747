/**
 * Additional admin styling for displaying tasks
 */
@tasksBgColor: #ecf0f5;
@tasksBorderStyle: 1px dotted darken(@tasksBgColor, 20%);
@tasksSpacing: 10px;

.task-column {
    background: darken(@tasksBgColor, 1%);
    border: @tasksBorderStyle;
    border-radius: 4px;
    padding: @tasksSpacing;

    &__title {
        font-weight: 700;
        color: #333;
        border-bottom: @tasksBorderStyle;
        padding: 0 @tasksSpacing @tasksSpacing;
        margin: 0 -@tasksSpacing @tasksSpacing;
    }
}
