/**
 * Additional admin styling for the celebration of Daisy's launch
 * - Can be toggled by the user if they wish to have it on at all times
 */
.birthday {
    .content-wrapper {
        background: url("../../img/hide.png") #f9f9f9 50% 50%;
    }
}

.birthday.fields {
    .content-wrapper {
        @media (min-width: 991px) {
            background-color: #ecf0f5;
            background-image: url("../../img/daisy.svg"), url("../../img/fields.svg");
            background-repeat: no-repeat, repeat-x;
            background-position: bottom right, bottom left;
            background-size: 50%, 100%;
        }

        @media (min-width: 1200px) {
            background-size: auto, auto;
        }
    }
}
