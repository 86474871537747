/**
 * Additional admin styling for .dl-metadata blocks
 */
.dl-metadata {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 0;

    dt,
    dd {
        border-top: 1px solid #f4f4f4;
        padding: 8px;
    }

    dt {
        flex-basis: 30%;
        max-width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    dd {
        flex-basis: 70%;
        max-width: 70%;
    }
}
