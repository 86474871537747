/**
 * Additional admin styling for .timeline blocks
 */
.timeline {
    &-item {
        .actions {
            padding: 7px;
            float: right;
        }

        .inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }
}

/** Allows a timeline item to have a coloured border to highlight it */
.timeline-inverse > li > .timeline-item.note-attachment-highlight {
    border-color: #337ab7;
    border-width: 2px;
}
