/**
 * Additional admin styling for alert and callout elements
 */

// Fix the *-info colouring to be less eye-popping
// `!important` required to overload existing colours
.alert.alert,
.callout.callout {
    &-info {
        background-color: #3c8dbc !important;
        border-color: #337ab7 !important;
    }
}
